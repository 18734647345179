import { createBrowserRouter, useRouteError } from "react-router-dom";
import { wrapCreateBrowserRouter } from "@sentry/react";

import ForgotPassword from "../components/auth/ForgotPassword";
import Router from "../components/auth/Router";
import SignIn from "../components/auth/SignIn";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const RethrowBoundary = () => {
  const error = useRouteError();

  if (error) throw error;

  return null;
};

export const unauthenticatedRouter = sentryCreateBrowserRouter([
  {
    element: <Router />,
    errorElement: <RethrowBoundary />, // rethrow to top level sentry boundary
    children: [
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "*", element: <SignIn /> },
    ],
  },
]);
