import { type ComponentType, lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { wrapCreateBrowserRouter } from "@sentry/react";

import { withViewSettings } from "../components/hoc/WithViewSettings";

import { RethrowBoundary } from "./unauthenticatedRouter";

export class LazyLoadError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "LazyLoadError";
  }
}

const rethrowLazyLoadError = (error: unknown) => {
  if (error instanceof Error) {
    throw new LazyLoadError(error.message);
  }
  throw new LazyLoadError(String(error));
};

const setViewSettings =
  (viewSettings: { title: string; permissions: string | null }, moduleKey = "default") =>
  (module: unknown) => ({
    default: withViewSettings(viewSettings)((module as Record<string, ComponentType<object>>)[moduleKey]),
  });

const AuthenticatedView = lazy(() =>
  import("../views/AuthenticatedView")
    .then((module) => ({ default: module.AuthenticatedView }))
    .catch(rethrowLazyLoadError),
);

const BalanceView = lazy(() =>
  import("../views/BalanceView")
    .then(setViewSettings({ title: "balance", permissions: "balance" }, "BalanceView"))
    .catch(rethrowLazyLoadError),
);

const DocumentsView = lazy(() =>
  import("../views/DocumentsView").then((module) => ({
    default: withViewSettings({
      title: "document-list",
      permissions: "document",
    })(module.DocumentsView),
  })),
);

const StatementListView = lazy(() =>
  import("../views/StatementListView")
    .then(setViewSettings({ title: "statement-list", permissions: "document.statement" }, "StatementListView"))
    .catch(rethrowLazyLoadError),
);

const NotificationListView = lazy(() =>
  import("../views/NotificationListView")
    .then(setViewSettings({ title: "notification-list", permissions: "document.notification" }, "NotificationListView"))
    .catch(rethrowLazyLoadError),
);

const InvestmentView = lazy(() =>
  import("../views/YieldView")
    .then(setViewSettings({ title: "investment", permissions: "investment" }, "YieldView"))
    .catch(rethrowLazyLoadError),
);

const SettingsPageView = lazy(() =>
  import("../views/SettingsPageView")
    .then(setViewSettings({ title: "settings", permissions: null }, "SettingsPage"))
    .catch(rethrowLazyLoadError),
);

const ManageCookiesForm = lazy(() => import("../components/ManageCookiesForm").catch(rethrowLazyLoadError));
const UserAttributesChangeForm = lazy(() =>
  import("../components/UserAttributesChangeForm").catch(rethrowLazyLoadError),
);
const MFAManageForm = lazy(() => import("../components/auth/MFAManageForm").catch(rethrowLazyLoadError));
const PasswordForm = lazy(() => import("../components/PasswordForm").catch(rethrowLazyLoadError));
const ManagePaymentMethods = lazy(() =>
  import("../components/PaymentMethods/ManagePaymentMethods")
    .then(setViewSettings({ title: "payment-methods", permissions: "settings.payment-methods" }))
    .catch(rethrowLazyLoadError),
);
const NewPaymentMethod = lazy(() =>
  import("../components/PaymentMethods/NewPaymentMethod")
    .then(setViewSettings({ title: "new-payment-method", permissions: "settings.payment-methods" }))
    .catch(rethrowLazyLoadError),
);
const PlaidOAuthCallback = lazy(() =>
  import("../components/PaymentMethods/PlaidOAuthCallback")
    .then(setViewSettings({ title: "plaid-oauth-callback", permissions: "settings.payment-methods" }))
    .catch(rethrowLazyLoadError),
);
const FinalizePlaidPaymentMethod = lazy(() =>
  import("../components/PaymentMethods/FinalizePlaidPaymentMethod")
    .then(setViewSettings({ title: "finalize-plaid-payment-method", permissions: "settings.payment-methods" }))
    .catch(rethrowLazyLoadError),
);

const TradeView = lazy(() =>
  import("../views/TradeView")
    .then(setViewSettings({ title: "trade", permissions: "trade" }, "TradeView"))
    .catch(rethrowLazyLoadError),
);
const PurchaseLayout = lazy(() =>
  import("../components/Trade/Purchase/PurchaseLayout")
    .then(setViewSettings({ title: "purchase", permissions: "trade.purchase" }))
    .catch(rethrowLazyLoadError),
);
const PurchaseMetalTransaction = lazy(() =>
  import("../components/Trade/Purchase/PurchaseMetal")
    .then(setViewSettings({ title: "purchase", permissions: "trade.purchase" }, "PurchaseMetalTransaction"))
    .catch(rethrowLazyLoadError),
);
const RecurringPurchases = lazy(() =>
  import("../components/Trade/Purchase/Recurring/RecurringPurchases")
    .then(setViewSettings({ title: "purchase", permissions: "trade.purchase.recurring" }))
    .catch(rethrowLazyLoadError),
);
const NewScheduleForm = lazy(() =>
  import("../components/Trade/Purchase/Recurring/NewScheduleForm")
    .then(setViewSettings({ title: "purchase", permissions: "trade.purchase.recurring" }))
    .catch(rethrowLazyLoadError),
);
const EditScheduleForm = lazy(() =>
  import("../components/Trade/Purchase/Recurring/EditScheduleForm")
    .then(setViewSettings({ title: "purchase", permissions: "trade.purchase.recurring" }))
    .catch(rethrowLazyLoadError),
);
const SendMetal = lazy(() =>
  import("../components/Trade/SendMetal")
    .then(setViewSettings({ title: "send", permissions: "trade.send" }, "SendMetal"))
    .catch(rethrowLazyLoadError),
);
const PendingTransaction = lazy(() =>
  import("../components/Trade/PendingTransaction")
    .then(setViewSettings({ title: "pending", permissions: "trade.pending" }, "PendingTransaction"))
    .catch(rethrowLazyLoadError),
);
const PendingTransactions = lazy(() =>
  import("../components/Trade/PendingTransactions")
    .then(setViewSettings({ title: "pending", permissions: "trade.pending" }, "PendingTransactions"))
    .catch(rethrowLazyLoadError),
);

const SpreadTableView = lazy(() =>
  import("../views/SpreadTableView")
    .then(setViewSettings({ title: "spreads-table", permissions: null }, "FeeTableView"))
    .catch(rethrowLazyLoadError),
);

const OpportunitiesView = lazy(() =>
  import("../views/OpportunitiesView")
    .then(setViewSettings({ title: "opportunities", permissions: "opportunities" }, "OpportunitiesView"))
    .catch(rethrowLazyLoadError),
);

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const authenticatedRouter = sentryCreateBrowserRouter([
  {
    element: <AuthenticatedView />,
    errorElement: <RethrowBoundary />, // rethrow to top level sentry boundary
    children: [
      { path: "*", element: <Navigate to="/balance" /> },
      { path: "balance", element: <BalanceView /> },
      { path: "opportunities", element: <OpportunitiesView /> },
      {
        path: "documents",
        element: <DocumentsView />,
        children: [
          { index: true, element: <Navigate to="statement" /> },
          { path: "statement", element: <StatementListView /> },
          { path: "notification", element: <NotificationListView /> },
        ],
      },
      { path: "statement", element: <StatementListView /> },
      { path: "yield", element: <InvestmentView /> },
      {
        path: "trade",
        element: <TradeView />,
        children: [
          { index: true, element: <Navigate to="purchase" /> },
          {
            path: "purchase",
            element: <PurchaseLayout />,
            children: [
              { index: true, element: <PurchaseMetalTransaction /> },
              { path: "recurring", element: <RecurringPurchases /> },
              { path: "recurring/new", element: <NewScheduleForm /> },
              { path: "recurring/edit/:scheduleId", element: <EditScheduleForm /> },
            ],
          },
          { path: "send", element: <SendMetal /> },
          {
            path: "pending",
            element: <PendingTransactions />,
          },
          {
            path: "pending/:taskId",
            element: <PendingTransaction />,
          },
        ],
      },
      {
        path: "settings",
        element: <SettingsPageView />,
        children: [
          { index: true, element: <Navigate to="password" /> },
          { path: "password", element: <PasswordForm /> },
          { path: "profile", element: <UserAttributesChangeForm /> },
          { path: "mfa", element: <MFAManageForm /> },
          { path: "cookies", element: <ManageCookiesForm /> },
          { path: "payment-methods", element: <ManagePaymentMethods /> },
          { path: "payment-methods/callback", element: <PlaidOAuthCallback /> },
          { path: "payment-methods/new", element: <NewPaymentMethod /> },
          { path: "payment-methods/new/final-steps", element: <FinalizePlaidPaymentMethod /> },
        ],
      },
      { path: "spreads", element: <SpreadTableView /> },
    ],
  },
]);
