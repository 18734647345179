import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useOutletContext } from "react-router-dom";
import { CognitoUser } from "@aws-amplify/auth";

import AuthPage from "./AuthPage";
import ConfirmSignIn from "./ConfirmSignIn";
import NewPassword from "./NewPassword";
import RecoveryCode from "./RecoveryCode";
import ResetPassword from "./ResetPassword";

export type ChallengedUser =
  | {
      challengeName: "RESET_PASSWORD" | "DISABLE_MFA";
      email: string;
    }
  | CognitoUser;

type ContextType = {
  setChallengedUser: (user: ChallengedUser | null) => void;
};

export default function Router() {
  const [challengedUser, setChallengedUser] = useState<ChallengedUser | null>(null);
  const { t } = useTranslation("common");

  if (!challengedUser) {
    return <Outlet context={{ setChallengedUser } satisfies ContextType} />;
  }

  if (challengedUser.challengeName === "DISABLE_MFA") {
    return <RecoveryCode email={challengedUser.email} setChallengedUser={setChallengedUser} />;
  }

  if (challengedUser.challengeName === "RESET_PASSWORD") {
    return <ResetPassword email={challengedUser.email} setChallengedUser={setChallengedUser} />;
  }
  if (challengedUser.challengeName === "NEW_PASSWORD_REQUIRED") {
    return <NewPassword challengedUser={challengedUser} setChallengedUser={setChallengedUser} />;
  }
  if (challengedUser.challengeName === "SOFTWARE_TOKEN_MFA") {
    return <ConfirmSignIn challengedUser={challengedUser} setChallengedUser={setChallengedUser} />;
  }

  return (
    <AuthPage>
      <p>{t("error.auth-signInFailed")}</p>
    </AuthPage>
  );
}

export function useSetChallengedUser() {
  return useOutletContext<ContextType>();
}