import { type UserNotification } from "@monetarymetals/sutil-types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Auth } from "aws-amplify";

import { EnumCommodity } from "../common/types/commodity";
import { ACCEPTED_COOKIES_KEY } from "../common/util/compliantStorage";
import { getIsoDateString } from "../common/util/dateUtils";
import { TReduxLondonFixData } from "../redux/app/appTypes";

import {
  type CreatePaymentDto,
  type CreatePurchaseDto,
  type CreatePurchaseScheduleDto,
  type GoldmineResult,
  type PaymentMethod,
  type Task,
  type VerifyPaymentDto,
  PlaidLinkResponse,
  PurchaseSchedule,
  SubmitPlaidTokenDto,
  UpdatePurchaseScheduleDto,
} from "./types/api";
import type {
  TApiAppAccountPayload,
  TApiInterestAndTaxPaginatedPayload,
  TApiLondonFixPayload,
  TApiOpportunityPayload,
  TApiPolicyPayload,
  TApiPositionPayload,
  TWithResult,
} from "./types/clientPortal";

// manually added type from https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#usequery
// since it was not easily derivable from package, replace if we find a way to do it
export type UseQueryResult<T> = {
  // Base query state
  originalArgs?: unknown; // Arguments passed to the query
  data?: T; // The latest returned result regardless of hook arg, if present
  currentData?: T; // The latest returned result for the current hook arg, if present
  error?: unknown; // Error result if present
  requestId?: string; // A string generated by RTK Query
  endpointName?: string; // The name of the given endpoint for the query
  startedTimeStamp?: number; // Timestamp for when the query was initiated
  fulfilledTimeStamp?: number; // Timestamp for when the query was completed

  // Derived request status booleans
  isUninitialized: boolean; // Query has not started yet.
  isLoading: boolean; // Query is currently loading for the first time. No data yet.
  isFetching: boolean; // Query is currently fetching, but might have data from an earlier request.
  isSuccess: boolean; // Query has data from a successful load.
  isError: boolean; // Query is currently in an "error" state.

  refetch: () => void; // A function to force refetch the query
};

const baseUrl = import.meta.env.VITE_API_GATEWAY_URL;

const tagTypes = [
  "Task",
  "PaymentMethod",
  "LinkToken",
  "Opportunity",
  "Policy",
  "AccountInfo",
  "PurchaseSchedule",
  "UserNotification",
] as const;

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      headers.set("Authorization", token);

      return headers;
    },
  }),
  tagTypes,
  endpoints: (builder) => ({
    createPurchaseOrder: builder.mutation<GoldmineResult<Task>, CreatePurchaseDto>({
      query: ({ eid, dryRun = false, ...body }) => ({
        url: `/transactions/purchase/${eid}?dryRun=${dryRun}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, response, request) => {
        if (request.dryRun) return [];
        const tags = ["Task"];

        if (response?.status === 429) {
          tags.push("Policy");
        }

        return tags as ("Task" | "Policy")[];
      },
    }),
    getTasks: builder.query<GoldmineResult<Task[]>, string>({
      query: (eid) => `/transactions/tasks/${eid}`,
      providesTags: ["Task"],
    }),
    getLondonFix: builder.query<TReduxLondonFixData, void>({
      query: () => ({ url: "/londonfix" }),
      transformResponse: (response: TApiLondonFixPayload) => ({
        [EnumCommodity.Gold]: {
          date: response.au_pm.date,
          price: parseFloat(response.au_pm.price),
        },
        [EnumCommodity.Silver]: {
          date: response.ag.date,
          price: parseFloat(response.ag.price),
        },
        [EnumCommodity.Palladium]: {
          date: response.pl_pm.date,
          price: parseFloat(response.pl_pm.price),
        },
        [EnumCommodity.Platinum]: {
          date: response.pt_pm.date,
          price: parseFloat(response.pt_pm.price),
        },
      }),
    }),
    getPolicyForCognitoUser: builder.query<TApiPolicyPayload, string>({
      query: (cognitoId) => `/policy/${cognitoId}`,
      providesTags: ["Policy"],
    }),
    getAccountInfo: builder.query<TApiAppAccountPayload, string>({
      query: (eid) => `/account/${eid}`,
      providesTags: (_result, _e, eid) => [{ type: "AccountInfo", id: eid }],
    }),
    getOpportunities: builder.query<TApiOpportunityPayload, string>({
      query: (eid) => `/lease/opportunities/${eid}`,
      transformResponse: (response: TWithResult<TApiOpportunityPayload>) => response.result,
      providesTags: (_result, _e, eid) => [{ type: "Opportunity", id: eid }],
    }),
    getAccountPosition: builder.query<TApiPositionPayload, string>({
      query: (eid) => `/position/${eid}`,
    }),
    getInterestAndTax: builder.query<TApiInterestAndTaxPaginatedPayload, string>({
      query: (eid) => `/interest_and_tax/${eid}`,
    }),
    getAccountStatement: builder.query<
      string,
      {
        accountNumber: string;
        month: string;
        year: string;
      }
    >({
      query: ({ accountNumber, month, year }) => ({
        url: `/statement/${accountNumber}/${month}/${year}`,
        responseHandler: (response) => response.arrayBuffer(),
      }),
      transformResponse: (buffer: ArrayBuffer) => btoa(String.fromCharCode(...new Uint8Array(buffer))),
    }),
    resetSignInsUntilMfaPrompt: builder.mutation<void, string>({
      query: (cognitoId) => ({
        url: `/user/settings/${cognitoId}/signInsUntilMfaPrompt`,
        method: "PUT",
      }),
      async onQueryStarted(cognitoId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getPolicyForCognitoUser", cognitoId, (draft) => {
            Object.assign(draft, {
              settings: {
                ...draft.settings,
                signInsUntilMfaPrompt: 3,
              },
            });
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateCookieConsent: builder.mutation<void, { cognitoId: string; allowed: boolean }>({
      query: ({ cognitoId, allowed }) => ({
        url: `/user/settings/${cognitoId}/cookieConsent`,
        method: "PUT",
        body: {
          cookieConsent: allowed,
          submittedDate: Date.now(),
        },
      }),
      async onQueryStarted({ cognitoId, allowed }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getPolicyForCognitoUser", cognitoId, (draft) => {
            Object.assign(draft, {
              settings: {
                ...draft.settings,
                cookieConsent: {
                  consentGiven: allowed,
                  submittedDate: Date.now(),
                },
              },
            });
          }),
        );

        try {
          await queryFulfilled;
          localStorage.setItem(ACCEPTED_COOKIES_KEY, String(allowed));
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateIgnoredLeases: builder.mutation<
      void,
      {
        cognitoId: string;
        eid: string;
        ignoredOpportunities: Array<string>;
      }
    >({
      query: ({ cognitoId, eid, ignoredOpportunities }) => ({
        url: `/user/settings/${cognitoId}/ignoredOpportunities`,
        method: "PUT",
        body: {
          eid,
          ignoredOpportunities,
        },
      }),
      async onQueryStarted({ cognitoId, eid, ignoredOpportunities }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getPolicyForCognitoUser", cognitoId, (draft) => {
            Object.assign(draft, {
              settings: {
                ...draft.settings,
                ignoredOpportunities: {
                  ...draft.settings.ignoredOpportunities,
                  [eid]: ignoredOpportunities,
                },
              },
            });
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    optIntoLease: builder.mutation<
      void,
      {
        eid: string;
        leaseSeries: string;
        leaseName: string;
        leaseNumber: string;
      }
    >({
      query: ({ eid, leaseSeries, leaseName, leaseNumber }) => ({
        url: `/lease/optout/${eid}/${leaseSeries}/${leaseName}/${leaseNumber}`,
        method: "POST",
      }),
      async onQueryStarted({ eid, leaseSeries, leaseName, leaseNumber }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getOpportunities", eid, (draft) => {
            const leaseInstance = `${leaseSeries}/${leaseName}#${leaseNumber}`;

            Object.assign(draft, {
              [leaseInstance]: {
                ...draft[leaseInstance],
                optout: "in",
              },
            });
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_result, _e, { eid }) => [{ type: "Opportunity", id: eid }],
    }),
    optOutOfLease: builder.mutation<
      void,
      {
        eid: string;
        leaseSeries: string;
        leaseName: string;
        leaseNumber: string;
        leaseFeedback: string;
      }
    >({
      query: ({ eid, leaseSeries, leaseName, leaseNumber, leaseFeedback = "" }) => ({
        url: `/lease/optout/${eid}/${leaseSeries}/${leaseName}/${leaseNumber}`,
        method: "DELETE",
        body: {
          leaseFeedback,
        },
      }),
      async onQueryStarted({ eid, leaseSeries, leaseName, leaseNumber }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getOpportunities", eid, (draft) => {
            const leaseInstance = `${leaseSeries}/${leaseName}#${leaseNumber}`;

            Object.assign(draft, {
              [leaseInstance]: {
                ...draft[leaseInstance],
                optout: "out",
              },
            });
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_result, _e, { eid }) => [{ type: "Opportunity", id: eid }],
    }),
    endReviewPeriod: builder.mutation<void, string>({
      query: (eid) => ({
        url: `/lease/review_period/${eid}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _e, eid) => [
        { type: "Opportunity", id: eid },
        { type: "AccountInfo", id: eid },
      ],
    }),
    generatePlaidLink: builder.query<PlaidLinkResponse, string>({
      query: (cognitoId) => `/plaid/link_token/${cognitoId}`,
      providesTags: (_result, _error, cognitoId) => [{ type: "LinkToken", id: cognitoId }],
    }),
    submitPlaidToken: builder.mutation<PaymentMethod[], SubmitPlaidTokenDto>({
      query: ({ cognitoId, ...body }) => ({
        url: `/plaid/public_token/${cognitoId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, { cognitoId }) => [{ type: "PaymentMethod", id: cognitoId }],
    }),
    getPaymentMethods: builder.query<PaymentMethod[], string>({
      query: (cognitoId) => `/payment_methods/${cognitoId}`,
      providesTags: (_result, _error, cognitoId) => [{ type: "PaymentMethod", id: cognitoId }],
    }),
    savePaymentMethod: builder.mutation<
      GoldmineResult<PaymentMethod>,
      { cognitoId: string; paymentMethod: CreatePaymentDto }
    >({
      query: ({ cognitoId, paymentMethod }) => ({
        url: `/payment_methods/${cognitoId}`,
        method: "POST",
        body: { ...paymentMethod, transferMethod: "ach" },
      }),
      invalidatesTags: (_result, _error, { cognitoId }) => [{ type: "PaymentMethod", id: cognitoId }],
    }),
    verifyPaymentMethod: builder.mutation<string, VerifyPaymentDto>({
      query: ({ cognitoId, paymentMethodId, amounts }) => ({
        url: `/payment_methods/${cognitoId}/verify`,
        method: "POST",
        body: { amounts, paymentMethodId },
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: (_result, _error, { cognitoId }) => [{ type: "PaymentMethod", id: cognitoId }],
    }),
    deletePaymentMethod: builder.mutation<{ message: string }, { cognitoId: string; paymentMethodId: string }>({
      query: ({ cognitoId, paymentMethodId }) => ({
        url: `/payment_methods/${cognitoId}/${paymentMethodId}`,
        method: "DELETE",
        responseHandler: (response) => response.text(),
      }),
      async onQueryStarted({ cognitoId, paymentMethodId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getPaymentMethods", cognitoId, (draft) =>
            draft?.filter((pm) => pm.id !== paymentMethodId),
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_result, _error, { cognitoId }) => [{ type: "PaymentMethod", id: cognitoId }],
    }),
    sendFeedbackSubmissionSuccessEmail: builder.mutation<void, { username: string; feedback: string }>({
      query: ({ username, feedback }) => ({
        url: "/feedback",
        method: "POST",
        body: { username, feedback },
      }),
    }),
    createPurchaseSchedule: builder.mutation<string, CreatePurchaseScheduleDto>({
      query: ({ cognitoId, eid, ...body }) => ({
        url: `/purchase_schedules/${cognitoId}/eid/${eid}`,
        method: "POST",
        body,
        responseHandler: (response) => response.text(),
      }),
      async onQueryStarted({ cognitoId, ...newSchedule }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getPurchaseSchedules", cognitoId, (draft) => {
            draft.push({
              ...newSchedule,
              cognitoId,
              scheduleId: "temp-id",
              startedOnDate: getIsoDateString(new Date()),
              disabled: false,
            } as PurchaseSchedule);
          }),
        );

        try {
          const { data: scheduleId } = await queryFulfilled;

          // fill in the scheduleId from the response
          dispatch(
            api.util.updateQueryData("getPurchaseSchedules", cognitoId, (draft) => {
              draft.at(-1)!.scheduleId = scheduleId;
            }),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    getPurchaseSchedules: builder.query<PurchaseSchedule[], string>({
      query: (cognitoId) => `/purchase_schedules/${cognitoId}`,
      providesTags: (_result, _error, cognitoId) => [{ type: "PurchaseSchedule", id: cognitoId }],
    }),
    updatePurchaseSchedule: builder.mutation<string, UpdatePurchaseScheduleDto>({
      query: ({ cognitoId, eid, ...body }) => ({
        url: `/purchase_schedules/${cognitoId}/eid/${eid}`,
        method: "PUT",
        body,
        responseHandler: (response) => response.text(),
      }),
      async onQueryStarted({ cognitoId, scheduleId, ...update }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getPurchaseSchedules", cognitoId, (draft) => {
            const index = draft.findIndex((schedule) => schedule.scheduleId === scheduleId);

            if (index !== -1) {
              Object.assign(draft[index], update);
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deletePurchaseSchedule: builder.mutation<string, { cognitoId: string; scheduleId: string }>({
      query: ({ cognitoId, scheduleId }) => ({
        url: `/purchase_schedules/${cognitoId}/schedule/${scheduleId}`,
        method: "DELETE",
        responseHandler: (response) => response.text(),
      }),
      async onQueryStarted({ cognitoId, scheduleId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData("getPurchaseSchedules", cognitoId, (draft) =>
              draft.filter((schedule) => schedule.scheduleId !== scheduleId),
            ),
          );
        } catch {
          /* empty */
        }
      },
      invalidatesTags: (_result, _error, { cognitoId }) => [{ type: "PurchaseSchedule", id: cognitoId }],
    }),
    getUserNotifications: builder.query<UserNotification[], { cognitoId: string; limit?: number; fromDate?: string }>({
      query: ({ cognitoId, limit, fromDate }) => {
        let url = `/user_notifications/cognito_id/${cognitoId}`;

        // Create query parameters only if limit or fromDate are provided
        const params = new URLSearchParams();

        if (limit) params.append("limit", limit.toString());
        if (fromDate) params.append("fromDate", fromDate);

        // Append the query string if params exist
        if (params.toString()) {
          url += `?${params.toString()}`;
        }

        return url;
      },
      providesTags: ["UserNotification"],
    }),
    archiveUserNotification: builder.mutation<void, string>({
      query: (eid) => ({
        url: `/user_notifications/${eid}/archive`,
        method: "PUT",
      }),
      onQueryStarted: async (eid, { dispatch, queryFulfilled, getState }) => {
        const { originalArgs } = api.util.selectInvalidatedBy(getState(), [{ type: "UserNotification" }])[0];

        const patchResult = dispatch(
          api.util.updateQueryData("getUserNotifications", originalArgs, (draft) => {
            const index = draft.findIndex((notification) => notification.id === eid);

            if (index > -1) {
              draft.splice(index, 1);
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    markReadUserNotification: builder.mutation<void, string>({
      query: (eid) => ({
        url: `/user_notifications/${eid}/read`,
        method: "PUT",
      }),
      onQueryStarted: async (eid, { dispatch, queryFulfilled, getState }) => {
        const { originalArgs } = api.util.selectInvalidatedBy(getState(), [{ type: "UserNotification" }])[0];

        const patchResult = dispatch(
          api.util.updateQueryData("getUserNotifications", originalArgs, (draft) => {
            const index = draft.findIndex((notification) => notification.id === eid);

            if (index > -1) {
              draft[index].readAt = new Date().toISOString();
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  endpoints,
  useCreatePurchaseOrderMutation,
  useGetTasksQuery,
  useGetLondonFixQuery,
  useGetPolicyForCognitoUserQuery,
  useGetAccountInfoQuery,
  useGetOpportunitiesQuery,
  useGetAccountPositionQuery,
  useGetInterestAndTaxQuery,
  useGetAccountStatementQuery,
  useLazyGetAccountStatementQuery,
  useResetSignInsUntilMfaPromptMutation,
  useUpdateCookieConsentMutation,
  useUpdateIgnoredLeasesMutation,
  useOptIntoLeaseMutation,
  useOptOutOfLeaseMutation,
  useEndReviewPeriodMutation,
  useGetPaymentMethodsQuery,
  useGeneratePlaidLinkQuery,
  useSubmitPlaidTokenMutation,
  useSavePaymentMethodMutation,
  useVerifyPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useSendFeedbackSubmissionSuccessEmailMutation,
  useCreatePurchaseScheduleMutation,
  useGetPurchaseSchedulesQuery,
  useUpdatePurchaseScheduleMutation,
  useDeletePurchaseScheduleMutation,
  useGetUserNotificationsQuery,
  useArchiveUserNotificationMutation,
  useMarkReadUserNotificationMutation,
} = api;
